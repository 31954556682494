import React from "react";
import {Navbar, Nav, Offcanvas, Container} from "react-bootstrap";

const navbar = (props) => {
    return (
        <Navbar bg="light" expand={false}>
            <Container fluid>
                <Navbar.Toggle aria-controls="offcanvasNavbar"/>
                <Navbar.Offcanvas
                    id="offcanvasNavbar"
                    aria-labelledby="offcanvasNavbarLabel"
                    placement="start"
                    scroll={true}
                    backdrop={false}
                    autoFocus={true}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{"Aktüel Sayfam"}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body style={{textAlign: "center"}}>
                        <Nav className="justify-content-end flex-grow-1 pe-3">
                            <Nav.Link href="/">Ana Sayfa</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}

export default navbar;