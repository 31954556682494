import React, {useEffect} from "react";
import { Carousel } from 'react-bootstrap';

const Homepage = (props) => {
    const [images, setImages] = React.useState({ data: [], loading: false });

    useEffect(() => {
        const fetchImages = async () => {
            const url = "https://aktuel_app.terkoyuncu.workers.dev/getByBrand/A101"
            const response = await fetch(url, {
                method: 'GET',
            });
            const responseData = await response.json(); // read response body and parse as JSON
            if(responseData["result"]) {
                console.log("Result", responseData["result"])
                setImages({ data: responseData["result"], loading: true });
            }
        }
        fetchImages();
    }, []);

    // Call something there
    return (
        <div style={{alignItems:"center" }}>
            <Carousel variant="dark" autoPlay={true} infiniteLoop={true} style={{paddingLeft: "400px"}}>
                {images.loading && images.data.map((item) => (
                <Carousel.Item>
                    <img src={item}/>
                </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default Homepage;