import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './components/Navbar.js';
import Homepage from './components/Homepage.js';

function App() {
    return (
        <div>
            <Navbar></Navbar>
            <div>
                <Router>
                    <Routes>
                        <Route path="/" element={<Homepage/>}/>
                    </Routes>
                </Router>
            </div>
        </div>
    )
        ;
}

export default App;
